<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="品牌">
              <el-select
                v-model="form.brandIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Style">
              <el-select
                v-model="form.styleIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人" :class="$i18n.locale">
              <el-select v-model="form.operateId" clearable filterable style="width:100%;">
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="操作时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="Loading" @click="queryClickSearch">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row :span="24" class="mb-3">
      <el-col :span="12">
        <el-button v-permission="'add'" type="primary" @click="handleAdd()">新增</el-button>
        <el-button v-permission="'batchImport'" class="mr-3" type="primary" @click="importDialogVisible=true">批量导入</el-button>
        <el-button v-permission="'deleteA'" class="mr-3" type="danger" @click="handleDelete()">删除</el-button>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-button v-permission="'exportA'" class="mr-3" type="primary" @click="exportDetail">导出</el-button>
      </el-col>
    </el-row>

    <vxe-table
      ref="multipleTable"
      v-loading="Loading"
      max-height="500px"
      border
      :data="tableDatas"
      align="center"
      keep-source
      :edit-rules="validRules"
      :edit-config="{trigger: 'manual', mode: 'row', autoClear: false}"
      @checkbox-all="({records})=>{selectlist=records}"
      @checkbox-change="({records})=>{selectlist=records}"
    >
      <vxe-table-column type="checkbox" width="60" />
      <vxe-table-column type="index" width="60" title="序号" />
      <vxe-table-column field="brandName" title="品牌" width="80" />
      <vxe-table-column field="styleName" title="Style" width="80" />
      <vxe-table-column field="seasonI18" title="季节" width="80" />
      <vxe-table-column field="position" title="定位" width="80" />
      <vxe-table-column field="ltDays" title="LT" width="80" />

      <vxe-table-column field="orderDays" title="下单所需时间（天）" :edit-render="{}" min-width="100">
        <template #edit="{ row }">
          <vxe-input v-model="row.orderDays" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="cnWarehouseDays" title="国内仓处理时间（天）" :edit-render="{}" min-width="100">
        <template #edit="{ row }">
          <vxe-input v-model="row.cnWarehouseDays" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="platformShelfDays" title="平台上架所需时间（天）" :edit-render="{}" min-width="100">
        <template #edit="{ row }">
          <vxe-input v-model="row.platformShelfDays" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="reorderFrequency" title="翻单频率（天/次）" :edit-render="{}" min-width="80">
        <template #edit="{ row }">
          <vxe-input v-model="row.reorderFrequency" type="integer" min="0" max="999999" placeholder="请输入自然数" />
        </template>
      </vxe-table-column>
      <vxe-table-column field="remark" title="备注" :edit-render="{}" width="80">
        <template #edit="{ row }">
          <vxe-input v-model="row.remark" type="textarea" maxlength="100" placeholder="请输入备注" />
        </template>
      </vxe-table-column>

      <vxe-table-column field="modifyByName" title="操作人" width="80" />
      <vxe-table-column field="modifyTime" title="操作时间" width="100" />

      <vxe-table-column title="操作" width="160">
        <template #default="{ row }">
          <template v-if="$refs.multipleTable.isActiveByRow(row)">
            <vxe-button @click="saveRowEvent(row)">保存</vxe-button>
            <vxe-button @click="cancelRowEvent(row)">取消</vxe-button>
          </template>
          <template v-else>
            <vxe-button @click="editRowEvent(row)">编辑</vxe-button>
          </template>
        </template>
      </vxe-table-column>
    </vxe-table>

    <Paging :pager="pager" end @pagination="pagerUpdate" />

    <!-- 新增弹窗 -->
    <el-dialog title="新增翻单时效" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false" center>
      <el-form ref="addForm" :model="addForm" label-width="140px" :rules="rules">
        <el-form-item :class="$i18n.locale" label="Style" prop="style">
          <el-select
            v-model="addForm.style"
            clearable
            filterable
            style="width: 90%;"
            :placeholder="$t('page.selectPlaceholder')"
          >
            <el-option
              v-for="item in styleLimitedOptions"
              :key="item.id"
              :label="item.styleName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="下单所需时间" prop="orderDays">
          <div>
            <el-input-number v-model="addForm.orderDays" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
        <el-form-item label="国内仓处理时间" prop="cnWarehouseDays">
          <div>
            <el-input-number v-model="addForm.cnWarehouseDays" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
        <el-form-item label="平台上架所需时间" prop="platformShelfDays">
          <div>
            <el-input-number v-model="addForm.platformShelfDays" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
        <el-form-item label="翻单频率" prop="reorderFrequency">
          <div>
            <el-input-number v-model="addForm.reorderFrequency" controls-position="right" placeholder="请输入自然数" :precision="0" :min="0" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="addForm.remark" type="textarea" placeholder="请输入内容" :maxlength="100" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="dialogVisibleSure">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 批量导入 弹窗 -->
    <BatchImport v-model="importDialogVisible" @refresh="queryClick" />
  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import { handleDownload, handleMonitorParams } from '@/utils'
import { LimitedqueryStyleList, queryStyleList, usersList, getFlippingApi, FlippingExport, InsertFlipping, ModifyFlipping, DeleteFlipping } from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'
import BatchImport from './components/BatchImport'
import { trackSiteSearch, handleBreadTitle, trackExportEvent, trackDialogEvent } from '@/utils/monitor'

export default {
  components: { Paging, BatchImport },

  data() {
    return {
      form: {
        value1: [],
        style: '',
        vendorId: ''
      },
      pager: {
        current: 1,
        size: 10,
        pages: 1,
        total: 0
      },
      brandOptions: [],
      styleOptions: [],
      styleLimitedOptions: [],
      useroptions: [],
      alldatas: [],
      limitedAlldatas: [],
      tableDatas: [],
      Loading: false,
      dialogVisible: false, // 新增修改弹窗
      importDialogVisible: false,

      addForm: {
        style: '',
        cnWarehouseDays: '',
        orderDays: '',
        platformShelfDays: '',
        reorderFrequency: ''
      },
      // 新增修改
      row: '',
      edit: false,
      rules: {
        style: { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' },
        cnWarehouseDays: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        orderDays: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        platformShelfDays: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        reorderFrequency: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
      },
      validRules: {
        orderDays: [{ required: true, message: this.$t('page.inputPlaceholder') }],
        platformShelfDays: [{ required: true, message: this.$t('page.inputPlaceholder') }],
        reorderFrequency: [{ required: true, message: this.$t('page.inputPlaceholder') }],
        cnWarehouseDays: [{ required: true, message: this.$t('page.inputPlaceholder') }]
      },
      submitLoading: false
    }
  },
  computed: {
    flippingTimeParams() {
      const [operateTimeStart, operateTimeEnd] = this.form.value1 || []
      const { brandIdList, operateId, styleIdList } = this.form
      const operateIdList = operateId ? [operateId] : []
      return Object.assign({}, this.pager, { brandIdList, styleIdList, operateIdList, operateTimeStart, operateTimeEnd })
    },
    addFlippingTimeParams() {
      const { style } = this.addForm
      const styleName = this.styleOptions.find(item => item.id === style).styleName
      return Object.assign({}, { styleId: style, styleName: styleName })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
         this.$refs.addForm?.clearValidate()
        this.$refs.addForm?.resetFields()
      }
    }
  },
  created() {

  },
  mounted() {
    this._queryBrandList()
    this._usersList()
    this._queryStyleList()
    this._limitedqueryStyleList()
    this.queryClick(1)
  },
  methods: {
    queryClickSearch() {
      this.queryClick(1)
      trackSiteSearch(
        JSON.stringify(handleMonitorParams(this.flippingTimeParams)),
        handleBreadTitle(this.$route),
        this.pager.size
      )
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.selectlist = []
      this.queryClick(1)
    },
    async queryClick(flag) {
      try {
        this.Loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await getFlippingApi(this.flippingTimeParams)
        if (code === 0) {
          this.pager = pager
          this.tableDatas = records
        }
        this.selectlist = []
      } catch (err) {
        console.log(err)
      } finally {
        this.Loading = false
      }
    },
    // 导出
    async exportDetail() {
      try {
        this.exportLoading = true
        const params = {}
        const idList = []
        if (Array.isArray(this.selectlist) && this.selectlist.length > 0) {
          this.selectlist.map(item => idList.push(item.id))
          Object.assign(params, { idList: idList })
        } else {
          Object.assign(params, this.flippingTimeParams)
        }
        const data = await FlippingExport(params)
        handleDownload(data, '翻单时效配置文件.xlsx')
        this.exportLoading = false
        trackExportEvent(handleBreadTitle(this.$route))
      } finally {
        this.exportLoading = false
      }
    },

    // 新增修改
    handleAdd() {
      this.addForm = {}
      this.dialogVisible = true
      trackDialogEvent(handleBreadTitle(this.$route) + '新增')
    },

    // 验证并提交
    async dialogVisibleSure() {
      this.$refs['addForm'].validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            const obj = Object.assign({}, this.addForm, this.addFlippingTimeParams)
            await InsertFlipping(obj)
            this.submitLoading = false
            this.dialogVisible = false
            this.queryClick(0)
          } catch (err) { console.log(err) } finally {
            this.submitLoading = false
            this.edit = false
          }
        }
      }
      )
    },

    // 删除
    handleDelete() {
      if (Array.isArray(this.selectlist) && this.selectlist.length) {
        this.$confirm(`你选中了${this.selectlist.length}条数据进行删除，该操作无法撤销，请确认！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const idList = this.selectlist.map(item => item.id)
          await DeleteFlipping(idList)
          this.queryClick(0)
          this.selectlist = []
          trackDialogEvent(handleBreadTitle(this.$route) + '删除')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.$message({
          message: '请至少选择一条数据删除',
          type: 'warning'
        })
        return false
      }
    },
    // 下拉信息获取
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    async _limitedqueryStyleList() {
      const { datas } = await LimitedqueryStyleList()
      this.styleLimitedOptions = datas
    },
    // 操作的修改、保存与取消
    editRowEvent(row) {
      const $table = this.$refs.multipleTable
      $table.setActiveRow(row)
      trackDialogEvent(handleBreadTitle(this.$route) + '编辑')
    },
    async saveRowEvent(row) {
      const $table = this.$refs.multipleTable
      const errMap = await $table.validate(row)
      if (!errMap) {
        $table.clearActived().then(async() => {
          this.loading = true
          const obj = Object.assign({}, row, { id: row.id })
          await ModifyFlipping(obj)
          this.queryClick(0)
          this.loading = false
          this.$notify({ message: '保存成功！', type: 'success' })
        })
      }
    },
    cancelRowEvent(row) {
      const $table = this.$refs.multipleTable
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
